
// Autogenerated
// -------------
// gulpfile.ts/wasm.ts --> generateBuildInfo

export const Build = {
    short: "0.73.9",
    version: "0.73.9 (2b193a42bb8728577711b215f2ee7fa1)",
    buildSeed: 1673510059952,
};
